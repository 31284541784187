import React, { useContext } from 'react';

import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import { makeStyles } from '@material-ui/core/styles';
import ThumbDownIcon from '@material-ui/icons/ThumbDown';
import ThumbUpIcon from '@material-ui/icons/ThumbUp';
import { tipOfTheDayDisLike, tipOfTheDayLike, tipOfTheDayTried } from 'sdk';

import InfoPanel from 'pages/Pathways/components/PathwaysDailyProgress/components/InfoPanel';
import { trackMixpanelEvent } from 'utils/integrations/mixpanel';
import { notifyError } from 'utils/notifications';

import UserContext from 'components/UserContext';

import { ReactComponent as LightBulb } from './assets/light-bulb.svg';

const HEADER_HEIGHT = 48;

const useStyles = makeStyles({
  infoPanelFooterButton: (theme) => ({
    height: theme.headerHeight,
    borderRadius: theme.headerHeight / 2,
    fontWeight: 600,
    textTransform: 'none',
    backgroundColor: '#fff',
    color: theme.secondaryColor,
    borderStyle: 'solid',
    borderWidth: '1px',
    borderColor: theme.primaryColor,
    '&:hover': {
      backgroundColor: theme.accentColor
    }
  }),

  backgroundVariation1: (theme) => ({
    backgroundColor: '#fff'
  }),

  backgroundVariation2: (theme) => ({
    backgroundColor: theme.accentColor
  }),

  headerStyle: (theme) => ({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    width: '100%',
    position: 'relative'
  }),
  headerContent: (theme) => ({
    position: 'absolute',
    top: -16,
    lineHeight: `${theme.headerHeight + 28}px`,
    fontWeight: 600,
    fontSize: '17px',
    letterSpacing: '0.15px'
  }),
  contentStyle: {
    fontWeight: 600,
    textAlign: 'center'
  }
});

const TipPanel = ({ tipOfTheDay, onLikeDislikeSuccess }) => {
  const panelTheme = {
    primaryColor: '#F8E6CC',
    secondaryColor: '#B27909',
    accentColor: '#FFF1E1',
    headerHeight: HEADER_HEIGHT
  };
  const styles = useStyles(panelTheme);
  const user = useContext(UserContext);

  const handleLike = async () => {
    const { success, error } = await tipOfTheDayLike({
      tip_of_the_day_id: tipOfTheDay.id
    });

    if (success) {
      trackMixpanelEvent(user.id, 'Button Click', {
        feature: 'pathways',
        view: 'daily progress tip',
        button: 'like'
      });
      onLikeDislikeSuccess();
    } else {
      notifyError(error);
    }
  };

  const handleDislike = async () => {
    const { success, error } = await tipOfTheDayDisLike({
      tip_of_the_day_id: tipOfTheDay.id
    });

    if (success) {
      trackMixpanelEvent(user.id, 'Button Click', {
        feature: 'pathways',
        view: 'daily progress tip',
        button: 'dislike'
      });
      onLikeDislikeSuccess();
    } else {
      notifyError(error);
    }
  };

  const handleTried = async () => {
    const { success, error } = await tipOfTheDayTried({
      tip_of_the_day_id: tipOfTheDay.id
    });

    if (success) {
      trackMixpanelEvent(user.id, 'Button Click', {
        feature: 'pathways',
        view: 'daily progress tip',
        button: 'tried it'
      });
      onLikeDislikeSuccess();
    } else {
      notifyError(error);
    }
  };

  let rootStyles = `${styles.infoPanelFooterButton} ${styles.backgroundVariation1}`;

  let likeStyles = rootStyles;
  let dislikeStyles = rootStyles;
  let triedStyles = rootStyles;

  if (tipOfTheDay?.reaction === 'like') {
    likeStyles = `${styles.infoPanelFooterButton} ${styles.backgroundVariation2}`;
  }

  if (tipOfTheDay?.reaction === 'dislike') {
    dislikeStyles = `${styles.infoPanelFooterButton} ${styles.backgroundVariation2}`;
  }

  if (tipOfTheDay?.is_tried) {
    triedStyles = `${styles.infoPanelFooterButton} ${styles.backgroundVariation2}`;
  }

  return (
    <div className={styles.infoPanelContainer}>
      <InfoPanel
        theme={panelTheme}
        header={
          <div className={styles.headerStyle}>
            <div className={styles.headerContent}>
              <LightBulb />
              Tip of the day...
            </div>
          </div>
        }
        content={
          <div className={styles.contentStyle}>
            {tipOfTheDay?.text || 'No tip of the day for today'}
          </div>
        }
        footer={
          tipOfTheDay?.text && (
            <>
              <Button
                variant="outlined"
                classes={{ root: triedStyles }}
                onClick={handleTried}
              >
                Tried It!
              </Button>
              <IconButton
                aria-label="yes"
                classes={{ root: likeStyles }}
                style={{ width: HEADER_HEIGHT }}
                onClick={handleLike}
              >
                <ThumbUpIcon />
              </IconButton>
              <IconButton
                aria-label="no"
                classes={{ root: dislikeStyles }}
                style={{ width: HEADER_HEIGHT }}
                onClick={handleDislike}
              >
                <ThumbDownIcon />
              </IconButton>
            </>
          )
        }
      />
    </div>
  );
};

export default TipPanel;
