import React, { useCallback } from 'react';

import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import {
  PATHWAYS_INSIGHTS_URL,
  STUDENTS_ASSIGNMENTS_LIST_URL,
  TEACHERS_SUBSCRIPTIONS_DASHBOARD_URL,
  TEACHER_STRATEGIES_OVERVIEW_URL
} from 'config/urls';
import { useOrganizationsSchools } from 'sdk';

import { colors } from 'theme/palette';
import { useMenu } from 'utils/hooks';
import { trackMixpanelEvent } from 'utils/integrations/mixpanel';
import { useIsMobile } from 'utils/resizing';

import Typography from 'components/Typography';

import { ReactComponent as AccountIcon } from './assets/account.svg';
import { ReactComponent as SignOutIcon } from './assets/sign-out.svg';
import styles from './styles.module.scss';

export const hideSchoolSelectMenuItem = ({
  organizations,
  selectedOrganization
}) => {
  return (
    organizations?.length === 1 && selectedOrganization?.schools.length === 1
  );
};

const AccountMenuButton = ({
  history,
  user,
  usage,
  logout,
  setSelectedOrganization,
  setSelectedSchool,
  selectedOrganization
}) => {
  const [anchorEl, openMenu, closeMenu] = useMenu();
  const isMobile = useIsMobile();

  const handleSelectSchoolDialog = useCallback(() => {
    trackMixpanelEvent(user.id, 'The User clicks the Select School option.', {
      userName: user.name,
      userEmail: user.email
    });
    const currentPath = history.location.pathname;
    setSelectedSchool(null);
    setSelectedOrganization(null);
    if (isMobile && user.is_pathways_user) {
      history.push(PATHWAYS_INSIGHTS_URL);
    } else {
      if (currentPath !== TEACHER_STRATEGIES_OVERVIEW_URL) {
        history.push(TEACHER_STRATEGIES_OVERVIEW_URL);
      }
    }
    closeMenu();
  }, [
    closeMenu,
    history,
    user.id,
    user.name,
    user.email,
    user.is_pathways_user,
    setSelectedSchool,
    isMobile,
    setSelectedOrganization
  ]);

  const redirectToSubscriptionsDashboard = useCallback(() => {
    history.push(TEACHERS_SUBSCRIPTIONS_DASHBOARD_URL);
    closeMenu();
  }, [closeMenu, history]);

  const openStudentApp = () =>
    window.open(STUDENTS_ASSIGNMENTS_LIST_URL, '_blank');

  const { data: organizations } = useOrganizationsSchools();

  const showAccountMenuData = !(isMobile && user.is_pathways_user);

  return (
    <>
      <IconButton onClick={openMenu} className={styles.iconButton}>
        <AccountIcon />
      </IconButton>
      <Menu
        keepMounted
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left'
        }}
        getContentAnchorEl={null}
        open={Boolean(anchorEl)}
        autoFocus={false}
        classes={{ list: styles.menuList, paper: styles.menuPaper }}
        onClose={closeMenu}
      >
        <MenuItem button={false}>
          <Typography variant="S-TEXT-1" color={colors.blue2}>
            {user.name}
          </Typography>
        </MenuItem>
        {!hideSchoolSelectMenuItem({
          organizations,
          setSelectedOrganization
        }) && (
          <MenuItem onClick={handleSelectSchoolDialog}>
            <Typography variant="S-TEXT-1" color={colors.blue2}>
              Select School
            </Typography>
          </MenuItem>
        )}
        {usage && showAccountMenuData && (
          <MenuItem onClick={redirectToSubscriptionsDashboard}>
            <Typography variant="S-TEXT-1" color={colors.blue2}>
              Account
            </Typography>
          </MenuItem>
        )}

        <MenuItem onClick={logout}>
          <Typography variant="S-TEXT-1" color={colors.blue2}>
            Sign out
          </Typography>
        </MenuItem>
        {user.is_teacher && user.student_id && showAccountMenuData && (
          <MenuItem onClick={openStudentApp}>
            <div className={styles.studentLink}>
              <Typography variant="S-TEXT-1" color={colors.blue2}>
                Student App
              </Typography>
              <SignOutIcon />
            </div>
          </MenuItem>
        )}
      </Menu>
    </>
  );
};

export default AccountMenuButton;
